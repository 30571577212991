<template>
    <aside id="nav-bar" class="nl-sidebar" :class="{ active: isMenuOpen }">
        <!--  Logo  -->
        <div class="nl-sidebar-top nav__logo">
            <router-link :to="homepage" style="margin: auto">
                <img class="logo-square mb-2" src="@/assets/img-v2/nl-icon.svg" alt="logo-square" />
                <img class="logo-full" src="@/assets/img-v2/nl-logo.svg" alt="logo-large" />
            </router-link>

            <div class="header_toggle text-white" :class="{ removePadding: isMenuOpen }" @click.prevent="toggleMenu()">
                <i id="header-toggle" class="fas fa-times" :class="{}" />
            </div>
        </div>

        <div v-if="!isAdminMenuOpen && checkRole('partenaire')">
            <b-field v-if="isMenuOpen">
                <b-switch v-model="isPartnerMenuOpen" type="is-warning" class="text-white m-0">
                    <span>
                        {{ isPartnerMenuOpen ? 'Partenaire' : 'Client' }}
                    </span>
                </b-switch>
            </b-field>
            <div v-else class="d-flex flex-column justify-content-center align-items-center text-white">
                <b-switch v-model="isPartnerMenuOpen" type="is-warning" size="is-small" class="text-white m-0 mb-1" />
                {{ isPartnerMenuOpen ? 'Part.' : 'Client' }}
            </div>
        </div>
        <hr v-if="!isAdminMenuOpen && checkRole('partenaire')" class="nl-nav-hr" />

        <div class="nl-nav-main">
            <!--  TopBar mobile  -->
            <div class="nl-nav-top">
                <div class="d-flex justify-content-center text-white">
                    <CreditGainMenu is-sidebar />
                </div>
                <SidebarDropdown :title="user.firstname" to="/profile" :icon="'fas fa-user'">
                    <router-link to="/profile" class="sidebar__sublink">
                        {{ $t('layout.profil') }}
                    </router-link>
                    <router-link to="/invoices" class="sidebar__sublink">
                        {{ $t('factures.title') }}
                    </router-link>
                    <router-link
                        v-if="
                            ['prospect', 'agence', 'partenaire', 'consultant', 'admin'].includes(user.role) &&
                            (isAdminMenuOpen || !isPartnerMenuOpen)
                        "
                        to="/credits"
                        class="sidebar__sublink"
                    >
                        {{ $t('mes-credits.title') }}
                    </router-link>
                    <router-link
                        v-if="
                            ['prospect', 'agence', 'partenaire', 'consultant', 'admin'].includes(user.role) &&
                            (isAdminMenuOpen || isPartnerMenuOpen)
                        "
                        to="/partner/earnings"
                        class="sidebar__sublink"
                    >
                        {{ $t('global.earnings') }}
                    </router-link>
                    <a href="#" class="sidebar__sublink" @click.prevent="logout">
                        {{ $t('global.disconnect') }}
                    </a>
                </SidebarDropdown>
                <SidebarDropdown title="Notifications" :to="'/search'" icon="fas fa-bell">
                    <router-link
                        v-for="(notification, index) in unread_notifications"
                        :key="`notification_${index}`"
                        :to="notification.destination || '/notifications'"
                        class="sidebar__sublink"
                    >
                        {{ notification.titre }}
                    </router-link>
                    <div v-if="unread_notifications.length > 0" class="dropdown-divider" />
                    <a class="sidebar__sublink" @click.prevent="readAllNotifications()">
                        {{ $t('layout.markallasread') }}
                    </a>
                    <router-link to="/notifications" class="sidebar__sublink">
                        {{ $t('layout.seeallnotifications') }}
                    </router-link>
                </SidebarDropdown>
                <hr class="nl-nav-hr" />
            </div>
            <!--  SideBar  -->
            <nav class="nl-nav">
                <div class="nav_list w-100">
                    <RedactorMenu v-if="user.role === 'redacteur'" />

                    <AdministrativeMenu v-if="user.role === 'administratif'" />
                    <template
                        v-if="
                            ['partenaire', 'prospect', 'agence', 'manager', 'consultant', 'admin'].includes(
                                user.role
                            ) && !isAdminMenuOpen
                        "
                    >
                        <CustomerMenu v-if="!isPartnerMenuOpen" />
                        <PartnerMenu v-if="isPartnerMenuOpen && checkRole('partenaire')" />
                    </template>

                    <AdminMenu v-if="user.role === 'admin' && isAdminMenuOpen" />
                </div>
            </nav>
        </div>
        <div class="navbar_bottom">
            <div v-if="checkRole('admin')" class="d-flex justify-content-center">
                <b-field v-if="isMenuOpen">
                    <b-switch v-model="isAdminMenuOpen" type="is-warning" class="text-white m-0">
                        <i class="fas fa-cogs" />
                    </b-switch>
                </b-field>
                <div v-else class="d-flex flex-column justify-content-center align-items-center text-white">
                    <b-switch v-model="isAdminMenuOpen" type="is-warning" size="is-small" class="text-white m-0 mb-2" />
                    <i class="fas fa-cogs" />
                </div>
            </div>
        </div>
    </aside>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex'
    import RedactorMenu from '@/components/UI/Menu/RedactorMenu'
    import AdminMenu from '@/components/UI/Menu/AdminMenu'
    import PartnerMenu from '@/components/UI/Menu/PartnerMenu'
    import CustomerMenu from '@/components/UI/Menu/CustomerMenu'
    import AdministrativeMenu from '@/components/UI/Menu/AdministrativeMenu'
    import SidebarDropdown from '@/components/UI/Menu/SidebarDropdown'
    import CreditGainMenu from '@/components/UI/Menu/CreditGainMenu'
    import NlStorage from "@/services/nl.storage";

    export default {
        name: 'Sidebar',
        components: {
            CustomerMenu,
            PartnerMenu,
            AdminMenu,
            RedactorMenu,
            AdministrativeMenu,
            SidebarDropdown,
            CreditGainMenu
        },
        watch: {
            $route() {
                if (window.innerWidth <= 768) {
                    this.isMenuOpen = false
                }
            }
        },
        computed: {
            ...mapState('auth', {
                user: state => state.user
            }),
            ...mapState({
                homepage: state => state.homepage
            }),
            ...mapGetters('notifications', {
                notifications: 'list',
                notificationLoading: 'isLoading',
                notificationLoaded: 'loaded',
                notificationTotal: 'total'
            }),

            unread_notifications: function () {
                return this.notifications.filter(notification => notification.lu === 0)
            },
            role() {
                return this.$store.state.auth.user.role
            },
            isMenuOpen: {
                get() {
                    return this.$store.state.isMenuOpen
                },
                set(value) {
                    this.$store.commit('setIsMenuOpen', value)
                }
            },
            isPartnerMenuOpen: {
                get() {
                    return this.$store.state.isPartnerMenuOpen
                },
                set(value) {
                    this.$store.commit('setIsPartnerMenuOpen', value)
                }
            },
            isAdminMenuOpen: {
                get() {
                    return this.$store.state.isAdminMenuOpen
                },
                set(value) {
                    this.$store.commit('setIsAdminMenuOpen', value)
                }
            }
        },
        methods: {
            ...mapActions('notifications', {
                getNotifications: 'list',
                deleteNotifications: 'delete',
                readOneNotification: 'setRead',
                readAllNotifications: 'setReadAll'
            }),
            checkRole(role) {
                if (this.user.role === 'admin') {
                    return true
                }

                return this.user.role === role
            },
            toggleMenu() {
                this.isMenuOpen = !this.isMenuOpen
            },
            logout() {
                NlStorage.clear()
                this.$router.go()
            }
        }
    }
</script>
