<template>
    <field v-model="input" class="nl-select" :class="stateClass" :rules="rules" :name="name">
        <template #field="{ fieldValue, onInput, fieldId }">
            <label v-if="label" :class="classLabel" :for="fieldId">{{ label }}</label>
            <div :class="{ prepend: $slots.prepend }">
                <div v-if="$slots.prepend" class="input-group-prepend">
                    <div :class="['input-group-text', { 'prepend-dark': prependDark }]">
                        <slot name="prepend" />
                    </div>
                </div>
                <div class="select w-100">
                    <select
                        :id="fieldId"
                        :name="name"
                        :value="fieldValue"
                        :disabled="disabled"
                        class=""
                        @change="
                            event => {
                                onInput(event.target.value)
                                $emit('change', event)
                            }
                        "
                        @focus="focus = !focus"
                        @blur="focus = !focus"
                    >
                        <option
                            v-for="(item, index) in items"
                            :key="name + fieldId + index"
                            :value="item.id"
                            :selected="fieldValue == item.id ? true : false"
                        >
                            {{ item[itemText] }}
                        </option>
                    </select>
                </div>
            </div>
        </template>
    </field>
</template>

<script>
    import Field from './Field'
    import FieldState from './mixins/FieldState.vue'

    export default {
        components: {
            Field
        },
        mixins: [FieldState],
        props: {
            label: {
                type: String,
                default: ''
            },
            classLabel: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'text'
            },
            items: {
                type: Array,
                required: true
            },
            value: {
                type: [String, Number],
                default: ''
            },
            rules: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: () => false
            },
            prependDark: {
                type: Boolean,
                default: () => false
            },
            itemText: {
                type: String,
                default: () => 'text'
            }
        },
        computed: {
            input: {
                get() {
                    return this.value
                },
                set(value) {
                    return this.$emit('input', value)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .nl-select {
        .hidden {
            display: none;
        }

        select {
            appearance: none;
            background-color: transparent;
            border: none;
            padding: 0.375rem 0.75rem;
            margin: 0;
            width: 100%;
            font-family: inherit;
            font-size: inherit;
            cursor: inherit;
            line-height: 1rem;
            outline: none;

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        select::-ms-expand {
            display: none;
        }

        .prepend {
            display: flex;

            .input-group-text {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            .select {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        .select {
            width: 100%;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            padding-right: 0.75em;
            cursor: pointer;
            line-height: 1.1;
            background-color: white;
            display: grid;
            grid-template-areas: 'select';
            align-items: center;
            position: relative;
        }

        .select:not(.select--multiple)::after {
            content: '';
            width: 0.8em;
            height: 0.5em;
            top: 60%;
            background-color: #3c3c3b;
            clip-path: polygon(0% 0%, 50% 100%, 100% 0);
            transform: rotate(0);
            justify-self: end;
        }

        select,
        .select:after {
            grid-area: select;
        }

        select[multiple] {
            padding-right: 0;
            height: 6rem;
        }

        select[multiple] option {
            white-space: normal;
        }

        .select--disabled {
            cursor: not-allowed;
            background-color: #eee;
            background-image: linear-gradient(to top, #ddd, #eee 33%);
        }

        label {
        }

        & .input-focus,
        & .input-filled {
            label {
            }
        }

        .prepend-dark {
            color: white;
            background-color: #1b2225;
            border: 0;
            border-radius: 10px;
        }
    }
</style>
